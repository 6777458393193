import React, { useEffect, useState } from 'react';
import "../../assets/css/SSN_V3/css/custom.scss";
import logowhite from "../../assets/css/SSN_V3/img/logo-white.png";
import avatar from "../../assets/css/SSN_V3/img/avatar.png";
import thanku from "../../assets/css/SSN_V3/img/tahnku-1.png";
import loader from "../../assets/css/SSN_V3/img/loader-1.gif";
import { useSsnFollowupLoad } from "../../Hooks/useSsnFollowupLoad";
import { queryString } from "../../Utility/QueryString";
import { DOCUMENT_OPTIONS } from '../../Constants/documentOptions';
import { IMMIGRATION_DOCUMENTS } from "../../Constants/documentOptions";
import { TAX_DOCUMENTS } from "../../Constants/documentOptions";
import { IDENTITY_DOCUMENTS } from "../../Constants/documentOptions";
import { useSaveDoc } from "../../Hooks/useSaveDoc";
import { usePendingDocumentDetails } from "../../Hooks/usePendingDocumentDetails";

const Collect_ssn_v3 = () => {

  const [currentSlide, setCurrentSlide] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [isInValidFile, setIsInValidFile] = useState(false);
  const [base64Data, setBase64Data] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { saveDocUpload, isLoading } = useSaveDoc();
  const { loadFollowup, isuseFollowupLoad } = useSsnFollowupLoad();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("atp_sub2");
  const { getDocData } = usePendingDocumentDetails();

  const handleContinue = () => {
    setCurrentSlide(2);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDocData(token);

        if (response.data.pending_details.includes('verify_immigration_status')) {
          setCurrentSlide(1);
        } else if (response.data.pending_details.includes('ssn_verification')) {
          setCurrentSlide(4);
        } else if (response.data.pending_details.includes('income_verification')) {
          setCurrentSlide(5);
        } else if (response.data.pending_details.includes('citizenship_verification')) {
          setCurrentSlide(6);
        } else if (response.data.pending_details.length === 0) {
          setCurrentSlide(7);
        }

      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    };

    fetchData();
  }, []);

  const handleNext2 = async (event) => {
    event.preventDefault();
    let uploadData = base64Data;
    const selectedDocument = document.querySelector('.form-select').value;
    if (!selectedDocument) {
      document.querySelector("#doc1").textContent =
      "Please select a document.";
      // setValidationMessage('Please select a document.');
      return;
    }
    document.querySelector("#doc1").textContent = "";
    const uploadedFile = document.querySelector('input[type="file"]').files[0];

    if (!uploadedFile) {
      document.querySelector("#upload1").textContent =
      "Please upload a document.";
      // setValidationMessage1('Please upload a document.');
      return;
    }
    document.querySelector("#upload1").textContent = "";
    const response = await saveDocUpload(
      uploadData,
      selectedDocument,
      'user_docs_store',
      token
    );

    setValidationMessage('');
    setCurrentSlide(null);
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setCurrentSlide(4);
    }, 2000);
  };



  const handleNext4 = async (event) => {
    event.preventDefault();
    let uploadData = base64Data;
    console.log("dfdsfdsf")
    console.log(uploadData);
    const selectedDocument = document.querySelector('.form-select').value;
    if (!selectedDocument) {
      document.querySelector("#doc2").textContent =
      "Please select a document.";
      // setValidationMessage('Please select a document.');
      return;
    }
    document.querySelector("#doc2").textContent = "";
    const uploadedFile = document.querySelector('input[type="file"]').files[0];

    if (!uploadedFile) {
      document.querySelector("#upload2").textContent =
      "Please upload a document.";
      // setValidationMessage('Please upload a document.');
      return;
    }
    document.querySelector("#upload2").textContent = "";

    const response = await saveDocUpload(
      uploadData,
      selectedDocument,
      'user_docs_store',
      token
    );

    setValidationMessage('');
    setCurrentSlide(null);
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setCurrentSlide(5);
    }, 2000);
  };

  const handleNext5 = async (event) => {
    event.preventDefault();
    let uploadData = base64Data;
    const selectedDocument = document.querySelector('.form-select').value;
    if (!selectedDocument) {
      document.querySelector("#doc3").textContent =
      "Please select a document.";
      // setValidationMessage('Please select a document.');
      return;
    }
    document.querySelector("#doc3").textContent ="";
    const uploadedFile = document.querySelector('input[type="file"]').files[0];

    if (!uploadedFile) {
      document.querySelector("#upload3").textContent =
      "Please upload a document.";
      // setValidationMessage('Please upload a document.');
      return;
    }
    document.querySelector("#upload3").textContent ="";
    const response = await saveDocUpload(
      uploadData,
      selectedDocument,
      'user_docs_store',
      token
    );

    setValidationMessage('');
    setCurrentSlide(null);
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setCurrentSlide(6);
    }, 2000);
  };

  const handleNext6 = async (event) => {
    event.preventDefault();
    let uploadData = base64Data;
    const selectedDocument = document.querySelector('.form-select').value;
    if (!selectedDocument) {
      document.querySelector("#doc4").textContent =
      "Please select a document.";
      // setValidationMessage('Please select a document.');
      return;
    }
    document.querySelector("#doc4").textContent ="";
    const uploadedFile = document.querySelector('input[type="file"]').files[0];

    if (!uploadedFile) {
      document.querySelector("#upload4").textContent =
      "Please upload a document.";
      // setValidationMessage('Please upload a document.');
      return;
    }
    document.querySelector("#upload4").textContent ="";
    const response = await saveDocUpload(
      uploadData,
      selectedDocument,
      'user_docs_store',
      token
    );

    setCurrentSlide(null);
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
      setCurrentSlide(7);
    }, 2000);
  };

  useEffect(() => {
    (async () => {
      try {
        const flpResponse = await loadFollowup(
          "ssn_followup_load",
          token,
          "v3/collect-ssn",
          queryString
        );
      } catch (error) {
        console.error('Error loading followup:', error);
      }
    })();
  }, []);


  const changeFilesHandler = (e) => {
    setSelectedFile(e.target.files[0]);
    let imageFile = e.target.files[0];
    if (imageFile) {
      setIsInValidFile(false);
      setIsFilePicked(true);
      let extension = e.target.files[0].type;
      let imgsize = e.target.files[0].size;
      if (imageFile) {
        let docName = e.target.name;
        console.log(docName);
        let reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (event) => {
          let data = { documentName: docName, fileData: event.target.result, docType: extension }
          console.log(data);
          setBase64Data(uploads => [...uploads, data]);
        }
      }
    } else {
      setIsInValidFile(true);
      setIsFilePicked(false);
    }
  };


  return (
    <>
      <div className="SSN_V3">
        <div className="main">
          <div className="popup-wrap">
            <div className="">
              <img width="28%" src={logowhite} alt="logo" />
            </div>
          </div>
          <div className="slides col-12">
            {currentSlide === 1 && (

              <div className={`slide-${currentSlide}`}>
                <div className="row profile">
                  <div className="col-2">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="col-6 text-start p-0">
                    <h2>Hi, First Name</h2>
                    <p>Expiration Date : 02/04/2023</p>
                  </div>
                  <div className="col-4"></div>
                </div>
                <div className="text-popup pt-3">
                  <h3>Secure Your Enrollment</h3>
                  <p className="pt-2 fs-16 text-start">
                    {" "}
                    Upload Required Documents Before the Deadline to Stay Enrolled!
                  </p>
                  <p className="pt-2 font-16">
                    We take your security extremely seriously, using bank-grade
                    encryption to protect your information.
                  </p>
                </div>
                <div className="btn-div mt-3 ">
                  <button className="next1 effect-1" onClick={handleContinue}>
                    CONTINUE
                  </button>
                </div>
              </div>

            )}

            {currentSlide === 2 && (
              <div className="slide-2">
                <div className="row profile">
                  <div className="col-2">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="col-6 text-start p-0">
                    <h2>Hi, First Name</h2>
                    <p>Expiration Date : 02/04/2023</p>
                  </div>
                  <div className="col-4 text-center">
                    <p>
                      Document:
                      <br />
                      <strong>
                        {" "}
                        <span>1</span>/4
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="text-popup pt-2">
                  <h3>Verify Immigration Status</h3>
                  <h5
                    className="mb-0 pt-2 text-start"
                    style={{ fontWeight: "normal !important" }}
                  >
                    {" "}
                    Please provide your proof of immigration status. Send one or more
                    documents
                  </h5>
                  <div className="mb-4 mt-3 pt-2">
                    <label>
                      Please choose the document you wish to upload from the list
                      provided below.
                    </label>
                    <select
                      className="form-select text-plc"
                      aria-label="Default select example"
                    >
                      {IMMIGRATION_DOCUMENTS.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="validation-message"> <p className="text-danger" id='doc1'></p></div>
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                      Upload Document
                    </label>
                    <input type="file" accept="image/*"
                      name="immigration_doc"
                      id="immigration_doc"
                      onChange={changeFilesHandler}
                    />
                  </div>
                  <div className="validation-message"> <p className="text-danger" id='upload1'></p></div>
                  {/* <div className="validation-message">
                    {validationMessage1 && <p className="text-danger">{validationMessage1}</p>}
                  </div> */}

                  <div className="btn-div mt-3 ">
                    <button className="back1 effect-2 cancel_but" onClick={() => setCurrentSlide(1)}>
                      BACK
                    </button>
                    <button className="next2 effect-2 submit_but" onClick={handleNext2}>
                      NEXT
                    </button>
                  </div>

                </div>
              </div>

            )}

            {showLoader && (
              <div className="slide-loader pt-5 mt-5">
                <div className="text-center col-12 justify-content-center">
                  <img
                    className="loader"
                    width="50%"
                    src={loader}
                    alt="loader"
                  />
                  <p className="txt_p">Please wait</p>
                </div>
              </div>
            )}

            {currentSlide === 4 && (
              <div className="slide-4">
                <div className="row profile">
                  <div className="col-2">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="col-6 text-start p-0">
                    <h2>Hi, First Name</h2>
                    <p>Expiration Date : 02/04/2023</p>
                  </div>
                  <div className="col-4 text-center">
                    <p>
                      Document:
                      <strong>
                        <br /> <span>2</span>/4
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="text-popup pt-1">
                  <h3>SSN Verification</h3>
                  <h5
                    className="mb-0 pt-2 text-white text-start"
                    style={{ fontWeight: "normal !important" }}
                  >
                    {" "}
                    We are required by law (US Patriot Act) to collect your SSN to
                    verify your identity.
                  </h5>
                  <div className="mb-4 mt-3 pt-2">
                    <label>
                      Please choose the document you wish to upload from the list
                      provided below.
                    </label>
                    <select
                      className="form-select text-plc"
                      aria-label="Default select example"
                    >
                      {DOCUMENT_OPTIONS.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="validation-message"> <p className="text-danger" id='doc2'></p></div>
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                      Upload Document
                    </label>
                    <input
                      className="form-control  form-control-lg"
                      type="file"
                      id="ssn_doc"
                      name="ssn_doc"
                      onChange={changeFilesHandler}
                    />
                  </div>

                </div>
                <div className="validation-message"> <p className="text-danger" id='upload2'></p></div>

                {/* <div className="validation-message">
                  {validationMessage && <p className="text-danger">{validationMessage}</p>}
                </div> */}
                <div className="btn-div mt-3 ">
                  <button className="back3 effect-2 cancel_but" onClick={() => setCurrentSlide(2)}>
                    BACK
                  </button>
                  <button className="next4 effect-2 submit_but" onClick={handleNext4}>
                    NEXT
                  </button>
                </div>

              </div>
            )}
            {currentSlide === 5 && (
              <div className="slide-6" >
                <div className="row profile">
                  <div className="col-2">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="col-6 text-start p-0">
                    <h2>Hi, First Name</h2>
                    <p>Expiration Date : 02/04/2023</p>
                  </div>
                  <div className="col-4 text-center">
                    <p>
                      Document:
                      <br />
                      <strong>
                        {" "}
                        <span>3</span>/4
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="text-popup pt-1">
                  <h3>Income Verification</h3>
                  <h5
                    className="mb-0 pt-2 text-white text-start"
                    style={{ fontWeight: "normal !important" }}
                  >
                    {" "}
                    Please provide proof of your annual income, which clearly indicates
                    your name, income amount, year, and other relevant details.
                  </h5>
                  <div className="mb-4 mt-3 pt-2">
                    <label>
                      Please choose the document you wish to upload from the list
                      provided below.
                    </label>
                    <select
                      className="form-select text-plc"
                      aria-label="Default select example"
                    >
                      {TAX_DOCUMENTS.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="validation-message"> <p className="text-danger" id='doc3' style={{marginTop:'20px'}}></p></div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                      Upload Document
                    </label>
                    <input
                      className="form-control  form-control-lg"
                      type="file"
                      id="incom_doc"
                      name="incom_doc"
                      onChange={changeFilesHandler}
                    />
                  </div>
                </div>
                <div className="validation-message"> <p className="text-danger" id='upload3'></p></div>
                {/* <div className="validation-message">
                  {validationMessage && <p className="text-danger">{validationMessage}</p>}
                </div> */}
                <div className="btn-div mt-3 ">
                  <button className="back5 effect-2 cancel_but" onClick={() => setCurrentSlide(4)}>
                    BACK
                  </button>
                  <button className="next6 effect-2 submit_but" onClick={handleNext5}>
                    NEXT
                  </button>
                </div>
              </div>

            )}
            {currentSlide === 6 && (
              <div className="slide-6" >
                <div className="row profile">
                  <div className="col-2">
                    <img src={avatar} alt="" />
                  </div>
                  <div className="col-6 text-start p-0">
                    <h2>Hi, First Name</h2>
                    <p>Expiration Date : 02/04/2023</p>
                  </div>
                  <div className="col-4 text-center">
                    <p>
                      Document:
                      <br />
                      <strong>
                        {" "}
                        <span>4</span>/4
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="text-popup pt-1">
                  <h3>Citizenship Verification</h3>
                  <h5
                    className="mb-0 pt-2 text-white text-start"
                    style={{ fontWeight: "normal !important" }}
                  >
                    {" "}
                    Please provide proof of your citizenship status
                  </h5>
                  <div className="mb-4 mt-3 pt-2">
                    <label>
                      Please choose the document you wish to upload from the list
                      provided below.
                    </label>
                    <select
                      className="form-select text-plc"
                      aria-label="Default select example"
                    >
                      {IDENTITY_DOCUMENTS.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="validation-message"> <p className="text-danger" id='doc4'></p></div>
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                      Upload Document
                    </label>
                    <input
                      className="form-control  form-control-lg"
                      type="file"
                      id="citizenship_doc"
                      name="citizenship_doc"
                      onChange={changeFilesHandler}
                    />
                  </div>
                </div>
                <div className="validation-message"> <p className="text-danger" id='upload4'></p></div>
                {/* <div className="validation-message">
                  {validationMessage && <p className="text-danger">{validationMessage}</p>}
                </div> */}
                <div className="btn-div mt-3 ">
                  <button className="back5 effect-2 cancel_but" onClick={() => setCurrentSlide(5)}>
                    BACK
                  </button>
                  <button className="next6 effect-2 submit_but" onClick={handleNext6}>
                    NEXT
                  </button>
                </div>
              </div>

            )}
            {currentSlide === 7 && (

              <div className="slide-7" >
                <div className="text-popup pt-5 mt-5">
                  <div>
                    <img width="50%" src={thanku} alt="thanku" />
                  </div>
                  <h2 className="text-center dark">Thank You</h2>
                  <h6 className="dark">
                    We have securely saved the documents you have provided.
                  </h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>

  )
}
export default Collect_ssn_v3;
