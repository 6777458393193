// documentOptions.js
// ssn_verification

export const DOCUMENT_OPTIONS = [
    { label: 'Select Document', value: '' },
    { label: 'Social Security card', value: 'social_security_card' },
    { label: '1040 Tax Return (federal or state versions acceptable)', value: '1040_tax_return_federal_or_state_versions_acceptable' },
    { label: 'W2 and/or 1099s (includes 1099 MISC, 1099G, 1099R, 1099SSA, 1099DIV, 1099S, 1099INT)', value: 'w2_and_or_1099s_includes_1099_MISC_1099G_1099R_1099SSA_1099DIV_1099s_1099INT' },
    { label: 'W4 Withholding Allowance Certificate (federal or state versions acceptable)', value: 'w4_withholding_allowance_cerficat_federal_or_state_versions_acceptable' },
    { label: '1095 (includes 1095A, 1095B, 1095C)', value: '1095_includes_1095A_1095B_1095C' },
    { label: 'Pay stub documentation', value: 'pay_stub_documentation_ssn' },
    { label: 'Social Security Administration documentation (includes 4029)', value: 'social_security_administration_documentation_includes_4029' },
    { label: 'Military record', value: 'military_record' },
    { label: 'U.S. Military ID card', value: 'us_military_id_card' },
    { label: 'Military dependent\'s ID card', value: 'military_dependents_id_card' },
    { label: 'Unemployment Benefits (Unemployment Benefits Letter)', value: 'unemployment_benefits_unemployment_benefits_letter' },
    { label: 'Court Order Granting a Name Change, that must have your original first and last name, new first and last name, and SSN', value: 'court_order_granting_a_name_change_that_must_have_your_original_first_and_last_name_new_frist_and_last_name_and_ssn' },
    { label: 'Divorce decree', value: 'divorce_decree' },
  ];

// immigrationDocuments.js
// verify_immigration_status

export const IMMIGRATION_DOCUMENTS = [
    { label: 'Select Document', value: '' },
    { label: 'Permanent Resident Card, "Green Card" (I-551)', value: 'permanant_resident_card' },
    { label: 'Reentry Permit (I-327)', value: 'reentry_permit' },
    { label: 'Employment Authorization Card (I-766)', value: 'employment_authorization_card' },
    { label: 'Machine Readable Immigrant Visa (with temporary I-551 language)', value: 'machine_readabale_immigrant_visa' },
    { label: 'Temporary I-551 Stamp (on Passport or I-94/I-94A)', value: 'temporary_stamp' },
    { label: 'Arrival/Departure Record (I-94/I-94A)', value: 'arrival_or_departure_record' },
    { label: 'Arrival/Departure Record in foreign passport (I-94)', value: 'arrival_or_departure_record_in_foreign_passport' },
    { label: 'Other', value: 'Other_immigration' },
  ];

// taxDocuments.js
// income_verification

export const TAX_DOCUMENTS = [
    { label: 'Select Document', value: '' },
    { label: '1040 tax return (federal or state) ', value: '1040_tax_return_federal_or_state' },
    {
      label: 'Wages and tax statement (W2 and/or 1099s, including 1099 MISC, 1099G, 1099R, 1099SSA, 1099DIV, 1099S, 1099INT)',
      value: 'wage_and_tax_statement_w2_and_or_1099s_including_1099_MISC_1099G_1099R_1099SSA_1099DIV_1099S_1099INT'
    },
    { label: 'Pay stub documentation', value: 'pay_stub_documentation_income' },
    { label: 'Self-employment documentation', value: 'self_employment_documentation' },
    { label: 'Social Security statements', value: 'social_security_statements' },
    { label: 'Unemployment benefits letter', value: 'unemployment_benefits_letter' },
    { label: 'Other', value: 'Other_income' },
  ];

// identityDocuments.js
// citizenship_verification

export const IDENTITY_DOCUMENTS = [
    { label: 'Select Document', value: '' },
    { label: 'U.S. passport', value: 'us_passport' },
    {
      label: 'Certificate of Naturalization (N-550/N-570)',
      value: 'certificate_of_naturalization_n550_N_570'
    },
    {
      label: 'Certificate of Citizenship (N-560/N-561)',
      value: 'certificate_of_citizenship_in_560_N_561'
    },
    {
      label: 'State-issued enhanced driver`s license (available in Michigan, New York, Vermont, and Washington',
      value: 'state_issue_enhanced_drivers_license_avaialable_in_michigan_new_yourk_vermont_and_washington'
    },
    {
      label: 'Document from a federally recognized Indian tribe that includes your name, the name of the federally recognized Indian Tribe that issued the document, and shows your membership, enrollment, or affiliation with the tribe.',
      value: 'document_from_a_federally_recognized_indian_tribe_that_inlcude_your_name_the_name_of_thhe_federally_recognized_indina_tribe_that_issue_the_document_and_show_your_membership_enrollment_or_affiliation_with_the_tribe'
    },
    { label: 'Other', value: 'Other_citizenship' },
  ];
