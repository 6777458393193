import { useState } from "react";
import { IdUploadApi } from "../api/Api";

export const useSaveDoc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const saveDocUpload = async (
    upload_data,
    document_type,
    message_type,
    token
  ) => {
    const response = await IdUploadApi.post("crm/v1/ssn-document-save", {
      upload_data,
      document_type,
      message_type,
      token
    });
    setIsLoading(false);
    return response;
  };
  return {
    isLoading,
    saveDocUpload,
  };
};
