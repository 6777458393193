import { Api } from "../api/Api";
export const usePendingDocumentDetails = () => {
  const getDocData = async (token) => {
    const response = await Api.get(
      "crm/v1/get-pending-documents-details?token=" + token,
      {}
    );
    return response;
  };
 
  return { getDocData };
};
